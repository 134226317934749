import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as PageBannerSVG } from "../../Assets/img/lkwb.svg";
import { siteInfo, menuInfo } from "../../Features/HomeApi/HomeSlice";
import { socialOBJKEY } from "../../Constants";
import FooterLinks from "../Components/Footer/FooterLinks";
import SocialLinks from "../Components/Footer/SocialLinks";
import ContactLinks from "../Components/Footer/ContactLinks";
const Footer = () => {
  const dispatch = useDispatch();
  let site_Info = useSelector((state) => state.home.siteInfo);
  // let bottom_Links = useSelector((state) => state.home.bottomLinks);
  let about_Links = useSelector((state) => state.home.aboutLinks);
  let resource_Links = useSelector((state) => state.home.resourcesLinks);
  useEffect(() => {
    dispatch(siteInfo());
    dispatch(menuInfo());
  }, [dispatch]);

  const getSocialLink = () => {
    let link;
    if (site_Info.social_links) {
      link = [...JSON.parse(JSON.stringify(site_Info.social_links))];
      // console.log(site_Info.social_links);
      link.forEach((item, index) => {
        if (item.facebook_page) {
          link[0].icon = socialOBJKEY.facebook_page;
          link[0].link = item.facebook_page;
        } else if (item.facebook_group) {
          link[1].link = item.facebook_group;
          link[1].icon = socialOBJKEY.facebook_group;
        } else if (item.youtube) {
          link[2].link = item.youtube;
          link[2].icon = socialOBJKEY.youtube;
        } else if (item.tik_tok) {
          link[3].link = item.tik_tok;
          link[3].icon = socialOBJKEY.tiktok;
        } else if (item.instagram) {
          link[4].link = item.instagram;
          link[4].icon = socialOBJKEY.instagram;
        }
      });
    }
    return link;
  };
  const socialLinks = {
    header: "Follow Us",

    links: getSocialLink() && getSocialLink(),
  };
  const aboutUsLinks = {
    header: "Quick Links",
    links: about_Links && [...about_Links],
  };

  const resourcesLinks = {
    header: "Resources",
    links: resource_Links && [...resource_Links],
  };
  let contactLinks;
  if (site_Info) {
    contactLinks = {
      header: "Contact Us",
      address: site_Info.address,
      phoneNum: site_Info.phone,
      mail: site_Info.email,
    };
  }
  const getLocation = window.location.pathname;
  return (
    <>
      {site_Info && site_Info.social_links && (
        <footer
          className={`footer-section ${
            getLocation != "/" ? "otherpage-footer" : ""
          }`}
        >
          {getLocation != "/" && (
            <div class="custom-shape-divider-top-1679479740">
              <svg
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1200 120"
                preserveAspectRatio="none"
              >
                <path
                  d="M985.66,92.83C906.67,72,823.78,31,743.84,14.19c-82.26-17.34-168.06-16.33-250.45.39-57.84,11.73-114,31.07-172,41.86A600.21,600.21,0,0,1,0,27.35V120H1200V95.8C1132.19,118.92,1055.71,111.31,985.66,92.83Z"
                  class="shape-fill"
                ></path>
              </svg>
            </div>
          )}

          <PageBannerSVG />
          <Container>
            <Row>
              <Col md={3}>
                <div className="footerlogo">
                  <img
                    src={site_Info.footer_logo}
                    alt="Learn Korean With Bibek"
                  />
                </div>
              </Col>
              <Col md={3}>
                <ContactLinks links={contactLinks} />
              </Col>
              <Col md={2} sm={6} xs={6}>
                <FooterLinks links={aboutUsLinks} />
              </Col>
              <Col md={2} sm={6} xs={6}>
                <FooterLinks links={resourcesLinks} />
              </Col>
              <Col md={2} sm={6}>
                <SocialLinks links={socialLinks} />
              </Col>
            </Row>

            <div className="bottomFooter text-center">
              <div className="copyrights">
                <p>
                  &copy; {new Date().getFullYear()} Learn Korean with Bibek. All
                  Rights Reserved.
                </p>
                <p>
                  Website Designed and Developed by: &nbsp;
                  <a
                    aria-label="genesis w tech"
                    href="https://www.genesiswtech.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Genesis Web Technology
                  </a>
                </p>
              </div>
            </div>
          </Container>
        </footer>
      )}
    </>
  );
};

export default Footer;
