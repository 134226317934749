import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Container, Row, Col } from "react-bootstrap";
import {
  singleHomeBlog,
  commentOnBlog,
} from "../../Features/DiscussionsAPI/DiscussionsSlice";
import { useDispatch, useSelector } from "react-redux";
import HTMLParser from "../Components/HTMLParser";
import { openModal, openLoginForm } from "../../Features/ViewSlice";
import ShareModal from "../Components/Share/ShareModal";
import {
  setFavorites,
  removeFavorites,
} from "../../Features/FavoriteApi/FavoriteSlice";
import DiscussionCommentAdd from "../Components/DiscussionComponents/DiscussionCommentAdd";
import DiscussionComment from "../Components/DiscussionComponents/DiscussionComment";
import MetaHelmet from "../Components/Helmet/MetaHelmet";
import MiniBlog from "../Components/Blog/MiniBlog";
import CustomAd from "../Components/CustomAd/CustomAd";
import ShareItem from "../Components/Share/ShareItem";
import authorImg from "../../Assets/img/fallbackimg.png";
import { Link } from "react-router-dom";
const BlogSingle = () => {
  const dispatch = useDispatch();
  let ad = useSelector(
    (state) => state.ad.allAdPositions["sidebar-blog-detail-homepage-306-x-250"]
  );
  let bannerad = useSelector(
    (state) => state.ad.allAdPositions["banner-blog-detail-homepage-1920-x-100"]
  );
  let token = useSelector((state) => state.auth?.currentUser?.token);
  let single_blog = useSelector((state) => state.discussion.singleBlogContent);
  const [isFavorite, setFavorite] = useState();
  let { id } = useParams();
  const loginModel = () => {
    dispatch(openModal("loginView"));
    dispatch(openLoginForm());
  };
  useEffect(() => {
    if (!token) {
      dispatch(singleHomeBlog({ id }));
    } else {
      dispatch(singleHomeBlog({ token, id })).then((res) => {
        if (res.payload.status === 200) {
          setFavorite(res.payload.data.data.isFavorite);
        }
      });
    }
  }, [dispatch, id]);

  const makeFavorite = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("favoritable_id", id);
    formdata.append("favoritable_type", "App\\Models\\Blog");
    dispatch(setFavorites({ formdata, token })).then((res) => {
      if (res.payload.status === 200) {
        setFavorite(true);
      }
    });
  };
  const removeFavorite = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("favoritable_id", id);
    formdata.append("favoritable_type", "App\\Models\\Blog");
    dispatch(removeFavorites({ formdata, token })).then((res) => {
      if (res.payload.status === 200) {
        setFavorite(false);
      }
    });
  };
  const [commentValue, setCommentValue] = useState("");
  const postCommentOnBlog = () => {
    if (!token) {
      loginModel();
    } else {
      const formData = new FormData();
      formData.append("body", commentValue);
      dispatch(commentOnBlog({ token, formData, id: id }));
    }
  };
  const handleComment = (e) => {
    setCommentValue(e.target.value);
  };
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {single_blog && (
        <>
          <MetaHelmet data={single_blog} />
          <main>
            <Container>
              <div className="singleblog-container ">
                <Link to="/blogs" className="goback anchor-text">
                  Go Back
                </Link>
                <div className="section-wrap mb-3">
                  <h3>{single_blog.title}</h3>
                </div>
                <Row>
                  <Col md={8}>
                    <div className="postBlog">
                      <div className="blog-meta">
                        <div className="blog-authorimg">
                          <img src={authorImg} alt="" />
                          <span>LKWB</span>
                        </div>
                        <div className="blog-date">
                          <i className="bi bi-stopwatch"></i>
                          <span>{single_blog.published_at}</span>
                        </div>
                        <div className="blog-date">
                          <i className="bi bi-eye"></i>
                          <span>{single_blog.view_count}</span>
                        </div>
                      </div>
                      <div className="blog-share">
                        <ShareItem />
                        <i className="bi bi-share" onClick={handleShow}></i>
                        <ShareModal show={show} handleClose={handleClose} />
                        {token ? (
                          <>
                            {isFavorite ? (
                              <a
                                aria-label="heart fill"
                                href="/#"
                                onClick={removeFavorite}
                              >
                                <i className="bi bi-heart-fill"></i>
                              </a>
                            ) : (
                              <a
                                aria-label="heart shape"
                                href="/#"
                                onClick={makeFavorite}
                              >
                                <i className="bi bi-heart"></i>
                              </a>
                            )}
                          </>
                        ) : (
                          <i className="bi bi-heart" onClick={loginModel}></i>
                        )}
                      </div>
                    </div>
                    <div className="page-content blog-content">
                      <img src={single_blog.image} alt={single_blog.title} />
                      <HTMLParser>{single_blog.description}</HTMLParser>
                    </div>
                    {bannerad.map((item, id) => (
                      <CustomAd item={item} key={id} />
                    ))}
                    <div className="addCommentBlog">
                      <h3>Add Comment</h3>
                      <DiscussionCommentAdd
                        value={commentValue}
                        addComment={postCommentOnBlog}
                        handleComment={handleComment}
                      />
                      {single_blog.comments &&
                        single_blog.comments.map((item, id) => (
                          <DiscussionComment
                            key={id}
                            comment={item}
                            type="replies"
                            replies={item.replies}
                          />
                        ))}
                    </div>
                  </Col>
                  <Col md={4}>
                    {single_blog.latest_blogs && (
                      <div className="latestblog sticky">
                        <h2>Latest Blogs</h2>
                        {single_blog.latest_blogs.map((item, id) => (
                          <MiniBlog blog={item} key={id} />
                        ))}
                      </div>
                    )}
                    {ad.map((item, id) => (
                      <CustomAd item={item} key={id} />
                    ))}
                  </Col>
                </Row>
              </div>
            </Container>
          </main>
        </>
      )}
    </>
  );
};

export default BlogSingle;
