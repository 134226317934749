import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import storageSession from "redux-persist/lib/storage/session";
import thunk from "redux-thunk";
import authReducer from "./Features/AuthSlice";
import profileReducer from "./Features/Profile_management/profileSlice";
import viewReducer from "./Features/ViewSlice";
import homeReducer from "./Features/HomeApi/HomeSlice";
import favReducer from "./Features/FavoriteApi/FavoriteSlice";
import progressReducer from "./Features/ProgressApi/ProgressSlice";
import setReducer from "./Features/SetsAPI/SetsSlice";
import localSetReducer from "./Features/SetsAPI/LocalSetSlice";
import discussionReducer from "./Features/DiscussionsAPI/DiscussionsSlice";
import grammarReducer from "./Features/GrammarApi/GrammarSlice";
import dictionaryReducer from "./Features/DictionaryApi/DictionarySlice";
import NotificationReducer from "./Features/NotificationApi/NotificationSlice";
import SearchReducer from "./Features/SearchApi/SearchSlice";
import AdReducer from "./Features/AdApi/AdSlice";
const localPersistConfig = { key: "localset", storage: storageSession }; // This is to session storage
const reducers = combineReducers({
  auth: authReducer,
  view: viewReducer,
  profile: profileReducer,
  grammar: grammarReducer,
  discussion: discussionReducer,
  home: homeReducer,
  notification: NotificationReducer,
  favorite: favReducer,
  progress: progressReducer,
  search: SearchReducer,
  dictionary: dictionaryReducer,
  set: setReducer,
  localset: persistReducer(localPersistConfig, localSetReducer),
  ad: AdReducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "view",
    "dictionary",
    "grammar",
    "home",
    "progress",
    "favorite",
    "discussion",
    "notification",
    "search",
    "ad",
    "blog",
  ],
};
const persistedReducer = persistReducer(persistConfig, reducers);
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
export default store;
