import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { ReactComponent as PageBannerSVG } from "../../../Assets/img/big-lkwb.svg";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { singleBookNote } from "../../../Features/HomeApi/HomeSlice";
import HTMLParser from "../../Components/HTMLParser";
import Loader from "../../Components/Loader/Loader";
const BooksNotesSingle = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  let singlebooknote = useSelector((state) => state.home.singlebooknotes);
  let isLoading = useSelector((state) => state.home.isLoading);
  useEffect(() => {
    dispatch(singleBookNote({ id }));
  }, [dispatch, id]);
  return (
    <>
      {!isLoading ? (
        <>
          {singlebooknote && singlebooknote.urls && (
            <>
              <div className="pageBanner">
                <PageBannerSVG />
                <Container>
                  <div className="page-wrap">
                    <h3>{singlebooknote.title}</h3>
                  </div>
                  <div className="custom-shape-divider-bottom-1678688493">
                    <svg
                      data-name="Layer 1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1200 120"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                        className="shape-fill"
                      ></path>
                    </svg>
                  </div>
                </Container>
              </div>
              <section className="section">
                <Container fluid="sm">
                  <Link className=" anchor-text  goback" to="/books-notes">
                    Go Back
                  </Link>
                  {singlebooknote.description && (
                    <div className="page-content">
                      <HTMLParser>{singlebooknote.description}</HTMLParser>
                    </div>
                  )}
                  <div className="books-items">
                    {singlebooknote.urls.length > 0 ? (
                      <>
                        {singlebooknote.urls.map((item, id) => (
                          <div key={id}>
                            <h3>
                              <a
                                href={item.url}
                                className="bookTitle"
                                target="_blank"
                              >
                                {item.title}
                              </a>
                            </h3>
                            <a
                              href={item.url}
                              target="_blank"
                              className="anchor-text"
                            >
                              Click Here
                            </a>
                          </div>
                        ))}
                      </>
                    ) : (
                      <div className="youhavenot-container">
                        <p>No Content Available</p>
                      </div>
                    )}
                  </div>
                </Container>
              </section>
            </>
          )}
        </>
      ) : (
        <div className="loading__mode bigLoading">
          <Loader></Loader>
        </div>
      )}
    </>
  );
};

export default BooksNotesSingle;
