import React from "react";
import { Helmet } from "react-helmet";
const MetaHelmet = ({ data, shareTo }) => {
  let excerpt =
    "We offer a variety of classes, including beginner, intermediate, and advanced classes. Classes on EPS TOPIK, Academic TOPIK I and II, KIIP Level Test Preparation, and KIIP Level 0 to 5 are a few to list. The institute's goal is to make the learning experience fun and engaging while helping students reach their full potential in the language.";
  return (
    <Helmet>
      <meta
        property="og:url"
        content={`${
          window.location.href.replace("/dashboard", "") +
          (shareTo ? shareTo : "")
        }`}
      />
      <meta property="og:image" content={data.image ? data.image : 'https://admin.lkwb.com.np/storage/advertisement/7439-KakaoTalk_Photo_2023-06-24-10-17-13.webp'} />
      <meta
        name="twitter:image"
        content={data.image ? data.image : 'https://admin.lkwb.com.np/storage/advertisement/7439-KakaoTalk_Photo_2023-06-24-10-17-13.webp'}
      />
      <meta
        property="twitter:title"
        content={data.title ? data.title : data.word}
      />
      <meta property="og:title" content={data.title ? data.title : data.word} />
      <meta
        property="og:description"
        content={data.excerpt ? data.excerpt : excerpt}
      />
      <meta
        property="twitter:description"
        content={data.excerpt ? data.excerpt : excerpt}
      />
    </Helmet>
  );
};

export default MetaHelmet;
