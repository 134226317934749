import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../../Components/Loader/Loader";
import { allSets } from "../../../../Features/SetsAPI/SetsSlice";
import UBTPackages from "./UBTPackages";
import NormalSetsItem from "../../Components/NormalSetsItem";
import SetsItem from "../../Components/SetsItem";
const UBT = () => {
  const dispatch = useDispatch();
  let token = useSelector((state) => state.auth.currentUser.token);
  let allSetslist = useSelector((state) => state.set.allSets);
  let testState = useSelector((state) => state.localset.questionState);
  let isLoading = useSelector((state) => state.set.isLoading);
  useEffect(() => {
    if (!testState.start) {
      dispatch(allSets(token));
    }
  }, [dispatch]);
  const checkIfAllBought = () => {
    let count = 0;
    allSetslist.forEach((item) => {
      if (item.status === true) {
        count++;
      }
    });
    return count;
  };
  return (
    <>
      {!isLoading ? (
        <>
          {allSetslist && (
            <>
              <div className="ubt__boughtSet">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <h2 className="dashboard-page-title">Purchased Set</h2>
                  <Link
                    to="/dashboard/ubt-test/purchasedset"
                    className="btn btn-small"
                  >
                    View All
                  </Link>
                </div>
                {allSetslist.filter((item) => item.status === true).length >
                0 ? (
                  <>
                    <div className="setstitem-container">
                      {allSetslist
                        .filter((item) => item.status === true)
                        .sort(
                          (a, b) =>
                            new Date(b.bought_at) - new Date(a.bought_at)
                        )
                        .slice(0, 3)
                        .map((item, id) => (
                          <NormalSetsItem key={id} details={item} />
                        ))}
                    </div>
                  </>
                ) : (
                  <div className="youhavenot-container">
                    <p>You have not purchased any sets.</p>
                  </div>
                )}
              </div>
              <div className="ubt-container">
                <Row>
                  <Col lg={9}>
                    <div>
                      <h2 className="dashboard-page-title">
                        LKWB UBT Test Sets
                      </h2>
                      <div className="ubtSetQuestions setstitem-container">
                        {allSetslist
                          .filter((item) => item.status === false)
                          .sort((a, b) => a.price - b.price)
                          .sort((a, b) => b.canAccessSet - a.canAccessSet)
                          .map((item, id) => (
                            <SetsItem key={id} details={item} />
                          ))}
                      </div>
                      {checkIfAllBought() === allSetslist.length && (
                        <div>
                          <p>You have bough all the sets</p>
                          <p>Click here to view all your sets</p>
                          <Link
                            to="/dashboard/ubt-test/purchasedset"
                            className="btn btn-small mt-4"
                          >
                            View All
                          </Link>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="sticky">
                      {/* <div className="bigreclaim">
                        <p>Click here, If transaction is complete, but the set has not opened.</p>
                        <div className=" reclaimbtn">
                          <button className="btn" onClick={showReclaim}>
                            Reclaim
                          </button>
                          <ReclaimModal reclaim={reclaim} closeReclaim={closeReclaim} />
                        </div>
                      </div> */}
                      <div className="package-bottom">
                        <div className="d-flex justify-content-between align-items-center">
                          <h2 className="dashboard-page-title">Packages</h2>
                        </div>
                        <UBTPackages />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </>
      ) : (
        <div className="loading__mode bigloading">
          <Loader />
        </div>
      )}
    </>
  );
};

export default UBT;
